import React from 'react'
import TextField from '@material-ui/core/TextField'

const readOnlyProps = {
  variant: 'filled',
  InputProps: {
    readOnly: true
  }
}
export default (props) => <TextField
  {...readOnlyProps}
  {...props}
/>
