import React from 'react'
import { linkifyGuidelines } from 'utils'

export default (props) => {
  let data = props.data
  if (props.fetched) {
    if (props.linkifyGuidelines) {
      data = linkifyGuidelines(data)
    }
    return <div dangerouslySetInnerHTML={{ __html: data || '' }} />
  } else {
    return 'Fetching'
  }
}
