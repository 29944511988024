
import React from 'react'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { format } from 'date-fns'

import styles from './styles.scss'


import SmartInput from 'containers/SmartInput'
import DollarInput from 'components/DollarInput'
import { DatePicker } from '@material-ui/pickers'
import Select from 'components/FormControlSelect'
import MenuItem from '@material-ui/core/MenuItem'
import MaterialAutosuggest from 'components/MaterialAutosuggest'

import { selectOptions } from 'config'
import { Checkbox, FormLabel, FormControl, RadioGroup, FormControlLabel, Radio } from '@material-ui/core'

@inject('FormState', 'ValidationState') @observer
class AgreementCriteriaConfirmation extends React.Component {
    render () {
      const { FormState } = this.props
      const { agreement } = FormState

      return <React.Fragment>
        <div className={styles.container}>
          <FormControl >
            <FormLabel id="row-radio-buttons-AAUP-contract-terms">
              <p>
                Per AAUP contract, faculty may request AAUP/OVPR Faculty Travel Funding support if they do not have available funding in individual or grant accounts which may be spent on professional development.
              </p>
              <p>
                Before proceeding, please attest to which of the options below applies to your travel plans:
              </p>
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="row-radio-buttons-AAUP-contract-terms"
              name="row-radio-buttons-group-contract-terms"
              value={agreement.selectedOption}
              onChange={(e) => agreement.selectedOption = e.target.value}
            >
              <FormControlLabel className={styles.contractOptionLabel} value="yes1" control={<Radio />} label="I do not have any available funding in individual or grant accounts which may be spent on professional development." />
              <FormControlLabel className={styles.contractOptionLabel} value="yes2" control={<Radio />} label="I have some, but not enough, available funding in individual or grant accounts which may be spent on professional development, and I agree to use this available funding first before using AAUP/OVPR Faculty Travel award monies." />
              <FormControlLabel className={styles.contractOptionLabel} value="no" control={<Radio />} label="I am not currently eligible for funding support from this program because I have available funding in individual or grant accounts which may be spent on professional development." />
            </RadioGroup>
          </FormControl>
        </div>
      </React.Fragment>
    }
}

export default AgreementCriteriaConfirmation
