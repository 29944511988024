import React from 'react'
import { observable } from 'mobx'
import { inject, observer } from 'mobx-react'

import TripTable from 'components/TripTable'
import Paper from '@material-ui/core/Paper'

import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'

import FilterPane from './FilterPane'

import styles from './styles.scss'

@inject('TripStore') @observer
class TravelRequests extends React.Component {
  componentDidMount () {
    this.props.TripStore.fetchTrips()
  }
  render () {
    const { TripStore: { tripsOnCurrentPage, filteredTrips } } = this.props
    return <Paper>
      <TripToolbar />
      <TripTable displayedTrips={tripsOnCurrentPage} allTrips={filteredTrips} />
    </Paper>
  }
}

@inject('TripStore') @observer
class TripToolbar extends React.Component {
  @observable showFilterPane = false

  render () {
    const { TripStore } = this.props

    return <Toolbar>
      <Typography variant='h6' id='tableTitle'>
        Travel Requests
      </Typography>
      <div className={styles.toolbarActions}>
        <TextField
          className={styles.searchField}
          label='Search by ID or faculty name'
          fullWidth
          value={TripStore.searchText}
          onChange={ev => { TripStore.searchText = ev.target.value }}
        />
        <IconButton
          onClick={() => { this.showFilterPane = true }}>
          <Icon>filter_list</Icon>
        </IconButton>
        <IconButton component='a' href='/api/trips/export'>
          <Icon>arrow_downward</Icon>
        </IconButton>
      </div>
      <FilterPane
        open={this.showFilterPane}
        onClose={() => { this.showFilterPane = false }}
        filterableProperties={TripStore.filterable}
        filters={TripStore.filters}
        filterOptions={TripStore.filterOptions}
        onFilterChange={filters => { TripStore.filters = filters }}
      />
    </Toolbar>
  }
}

export default TravelRequests
