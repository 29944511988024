import React from 'react'

import { inject, observer } from 'mobx-react'
import TextField from '@material-ui/core/TextField'
import EditablesPaper from 'components/EditablesPaper'

import Validator from 'validatorjs'
import { mailingOptionsRules, mailingOptionsHelperText } from 'data/rules'

export default
@inject('EditableMailingOptionsStore') @observer
class MailingOptionsInput extends React.Component {
    state = {
      validated: true
    }

    onChange = (e, mailingOptionKey) => {
      const rule = mailingOptionsRules[mailingOptionKey]
      const validation = new Validator({ value: e.target.value }, rule)
      this.setState({ validated: validation.passes() })
      this.props.EditableMailingOptionsStore.onChange(e, mailingOptionKey)
    }

    render () {
      const { EditableMailingOptionsStore, mailingOptionKey } = this.props
      const option = EditableMailingOptionsStore[mailingOptionKey]
      const error = !this.state.validated
      const helperText = error && mailingOptionsHelperText[mailingOptionKey]
      return <EditablesPaper>
        <TextField
          {...option}
          error={error}
          helperText={helperText}
          onChange={(e) => this.onChange(e, mailingOptionKey)}
        />
      </EditablesPaper>
    }
}
