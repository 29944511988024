import React from 'react'
import { inject, observer } from 'mobx-react'
import GuidelinesInput from './GuidelinesInput'
import GeneralEditablesForm from 'containers/GeneralEditablesForm'

export default
@inject('EditableGuidelinesStore', 'UiState') @observer
class GuidelinesEdit extends React.Component {
  componentDidMount () {
    this.props.EditableGuidelinesStore.fetchAll()
  }
  render () {
    const { EditableGuidelinesStore: { allKeys } } = this.props
    return <GeneralEditablesForm storeName={'Editable Guidelines'}>
      {allKeys.map(key => <GuidelinesInput key={key} guidelineKey={key} />)}
    </GeneralEditablesForm>
  }
}
