import { observable, action, computed } from 'mobx'
import { getMailingOptions, submitMailingOptions } from 'transport/editable-mailing-options'
import { mailingOptionsRules } from 'data/rules'
import Validator from 'validatorjs'
import { omit } from 'lodash'

class EditableMailingOptionsStore {
    @observable daysToSendReminderAfterEndDate = null
    @observable fetched = false
    @observable submitting = false
    @observable errorMessage = null

    @action async fetchAll () {
      if (!this.fetched) {
        try {
          getMailingOptions()
            .then(res => res.forEach(each => {
              const { key, ...rest } = each
              this[key] = rest
            }))
        } finally {
          this.fetched = true
        }
      }
    }

    @action onChange (e, key) {
      this[key].value = e.target.value
    }

    async submit () {
      this.submitting = true
      const body = this.allKeys.map(each => {
        const data = { value: this[each].value }

        const rule = mailingOptionsRules[each]
        let validation = new Validator(data, rule)

        if (validation.fails()) {
          this.errorMessage = 'Failed to updated Mailing Options. Not all input fields are valid'
          this.submitting = false
          throw new Error(this.errorMessage)
        }

        return { key: each, ...data }
      })
      submitMailingOptions(body)
      this.submitting = false
    }

    @computed get allKeys () {
      return Object.keys(omit(this, ['fetched', 'submitting', 'errorMessage']))
    }
}

const singleton = new EditableMailingOptionsStore()
export default singleton
