import React from 'react'
import { inject, observer } from 'mobx-react'
import styles from './styles.scss'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Button from '@material-ui/core/Button'
import LinearProgress from '@material-ui/core/LinearProgress'

/*
Description:
 - This high-order component is used as a template to create any of the "Editables" pages
How to use:
 - This component takes in a storeName(STRING) as props and the Input fields as its children
 - The corresponding mobx store should be injected into this component and the store must have
'submitting' as one of its observables (to display the LinearProgress bar) and 'submit' as
an async action( to submit the form)
 - The new storeName must be manually added as a case in the switch statement in getStore so
that the form can dynamically use store based on their name.
*/

export default
@inject('EditableGuidelinesStore', 'EditableFieldsStore', 'EditableMailingOptionsStore', 'UiState') @observer
class GeneralEditablesForm extends React.Component {
    submit = async () => {
      const { UiState, storeName } = this.props
      const store = this.getStore()

      try {
        await store.submit()
      } catch (err) {
        const storeErrorMessage = store.errorMessage
        // If there is customized message to be display, display it. If not, display the general message
        if (storeErrorMessage) {
          UiState.addSnackbarMessage(storeErrorMessage, 'failure')
        } else {
          UiState.addSnackbarMessage(
            `Failed to update ${storeName}. Please try again later.`,
            'failure'
          )
        }
        console.error(err)
        return
      }
      UiState.addSnackbarMessage(`Sucessfully updated ${storeName}.`, 'success')
    }

    getStore = () => {
      const { storeName, EditableGuidelinesStore, EditableFieldsStore, EditableMailingOptionsStore } = this.props
      switch (storeName) {
        case 'Editable Guidelines':
          return EditableGuidelinesStore
        case 'Editable Fields':
          return EditableFieldsStore
        case 'Editable Mailing Options':
          return EditableMailingOptionsStore
        default:
          return null
      }
    }
    render () {
      const { storeName, children } = this.props
      const submitting = this.getStore().submitting
      return <FormControl className={styles.editablesForm}>
        { submitting ? <LinearProgress /> : null }
        <Typography className={styles.editablesTitle} variant='h3'>{storeName}</Typography>
        <Button variant='contained' color='primary' onClick={this.submit}>Save Changes</Button>
        {children}
      </FormControl>
    }
}
