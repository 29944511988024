import { observable, action, computed } from 'mobx'
import { getGuidelines, submitGuidelines } from 'transport/editable-guidelines'
import { omit } from 'lodash'

class EditableGuidelinesStore {
    @observable welcomeText = ''
    @observable openFundingPeriod = ''
    @observable upcomingFundingPeriod = ''
    @observable remainingFunds = ''
    @observable loginPage = ''
    @observable loginPageWarnings = ''
    @observable finished = ''
    @observable defaultSidebarContent = ''
    @observable fetched = false
    @observable submitting = false

    @action async fetchAll () {
      if (!this.fetched) {
        try {
          getGuidelines()
            .then(res => { Object.keys(res).forEach(each => { this[each] = res[each] }) })
        } finally {
          this.fetched = true
        }
      }
    }

    @action onChange (content, key) {
      this[key] = content
    }

    async submit () {
      this.submitting = true
      const body = this.allKeys.map(each => { return { key: each, data: this[each] } })
      await submitGuidelines(body)
      this.submitting = false
    }

    @computed get allKeys () {
      return Object.keys(omit(this, ['fetched', 'submitting']))
    }
}

const singleton = new EditableGuidelinesStore()
export default singleton
