import React from 'react'
import ButtonBase from '@material-ui/core/ButtonBase'
import Paper from '@material-ui/core/Paper'
import { Link } from 'react-router-dom'

import styles from './styles.scss'

export default
class Editables extends React.Component {
  render () {
    const buttons = [
      {
        label: 'Fields',
        slug: 'fields'
      },
      {
        label: 'Guidelines',
        slug: 'guidelines'
      },
      {
        label: 'Mailing Options',
        slug: 'mailingoptions'
      }
    ]
    return <div className={styles.editablesContainer}>
      { buttons.map(button =>
        <ButtonBase key={button.slug} className={styles.editablesButton}>
          <Link to={`/admin/editables/${button.slug}`}>
            <Paper className={styles.editablesText}>
              <span>{button.label}</span>
            </Paper>
          </Link>
        </ButtonBase>)}
    </div>
  }
}
