
import React from 'react'
import { action } from 'mobx'
import { inject, observer } from 'mobx-react'
import { format } from 'date-fns'

import SmartInput from 'containers/SmartInput'
import DollarInput from 'components/DollarInput'
import { DatePicker } from '@material-ui/pickers'
import Select from 'components/FormControlSelect'
import MenuItem from '@material-ui/core/MenuItem'
import MaterialAutosuggest from 'components/MaterialAutosuggest'

import { selectOptions } from 'config'

@inject('FormState', 'ValidationState') @observer
class SmartInputGenerator extends React.Component {
    @action selectTraveler (suggestion) {
    const { FormState } = this.props
    FormState.traveler.netid = suggestion.netid
    FormState.traveler.firstName = suggestion.firstName
    FormState.traveler.lastName = suggestion.lastName
  }

    render () {
      const { FormState } = this.props
      return <React.Fragment>
        { this.props.data ? this.props.data.map(editableField => {
          const { type, field, fieldSlug } = editableField

          switch (type) {
            case 'TEXT':
              return <SmartInput key={field} {...editableField} />

            case 'DOLLAR_INPUT':
              return <SmartInput key={field} component={DollarInput} {...editableField} />

            case 'DATE_PICKER':
              return <SmartInput
                key={field}
                component={DatePicker}
                InputLabelProps={{ shrink: true }}
                autoOk
                format='MMMM do, yyyy'
                minDate={field === 'travelDetails.endDate' ? FormState.travelDetails.startDate : null}
                placeholder={format(new Date(), 'MMMM do, yyyy')}
                {...editableField}
              />

            case 'SELECT':
              return <SmartInput key={field} component={Select} {...editableField}>
                {
                  selectOptions[field].map((method, i) =>
                    <MenuItem key={i} value={method}>{method}</MenuItem>
                  )}
              </SmartInput>

            case 'AUTO_SUGGEST':
              return <SmartInput
                key={field}
                component={MaterialAutosuggest}
                suggestions={[...FormState.travelerSuggestions]}
                onSuggestionsFetchRequested={() => FormState.fetchTravelerSuggestions()}
                onSuggestionsClearRequested={() => { FormState.travelerSuggestions = [] }}
                getSuggestionValue={s => s[fieldSlug]}
                onSuggestionSelected={(_, { suggestion }) => {
                  this.selectTraveler(suggestion)
                }}
                {...editableField}
              />

            default:
              return null
          }
        }) : 'Fetching...' }
      </React.Fragment>
    }
}

export default SmartInputGenerator
