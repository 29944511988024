import React from 'react'

import { inject, observer } from 'mobx-react'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import Toolbar from '@material-ui/core/Toolbar'
import Container from '@material-ui/core/Container'
import TripTable from 'components/TripTable'

import Input from '@material-ui/core/Input'

import styles from './styles.scss'

export default
@inject('TripStore') @observer
class ReimbursementReminders extends React.Component {
  componentDidMount () {
    this.props.TripStore.fetchTrips()
  }
  render () {
    const { TripStore: { reimbursementRemindersOnCurrentPage, reimbursementReminders } } = this.props
    return <Paper>
      <ReimbursementReminderToolbar />
      <TripTable displayedTrips={reimbursementRemindersOnCurrentPage} allTrips={reimbursementReminders} />
    </Paper>
  }
}

@inject('TripStore') @observer
class ReimbursementReminderToolbar extends React.Component {
  render () {
    const { TripStore } = this.props
    const dayInput = <Input
      className={styles.dayInput}
      value={TripStore.daysRemindedAgo}
      onChange={env => { TripStore.daysRemindedAgo = env.target.value }}
    />
    return <Toolbar>
      <Typography variant='h6' id='tableTitle'>
            Reimbursement Reminders
      </Typography>
      <Container className={styles.container}>
            Show all Trips that are not yet disbursed but were reminded more than {dayInput} days ago
      </Container>
    </Toolbar>
  }
}
