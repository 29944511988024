import React from 'react'

import { inject, observer } from 'mobx-react'

import ContentEditor from 'components/ContentEditor'
import TextField from '@material-ui/core/TextField'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import EditablesPaper from 'components/EditablesPaper'

export default
@inject('EditableFieldsStore') @observer
class FieldsInput extends React.Component {
  render () {
    const { EditableFieldsStore, stepName, fieldSlug, field } = this.props
    const instructions = EditableFieldsStore[stepName][fieldSlug].sideBarInstruction

    return <EditablesPaper>
      <ReadOnlyTextField label={'Field Key'} value={field} />
      <TextField
        label={'Label'}
        value={EditableFieldsStore[stepName][fieldSlug].label}
        onChange={(e) => EditableFieldsStore.onLabelChange(e, stepName, fieldSlug)} />
      { instructions &&
      <React.Fragment>
        <ReadOnlyTextField label={'Field Type'} value={'Side Bar Instructions'} />
        <ContentEditor
          content={EditableFieldsStore[stepName][fieldSlug].sideBarInstruction}
          onContentStateChange={(e) => EditableFieldsStore.onSideBarInstructionChange(e, stepName, fieldSlug)}
        />
      </React.Fragment>
      }
    </EditablesPaper>
  }
}
