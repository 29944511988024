import React from 'react'
import { inject, observer } from 'mobx-react'

import Button from '@material-ui/core/Button'
import FetchedDataLoader from 'components/FetchedDataLoader'

import { temporaryLoginPageMessage } from 'config'
import styles from './styles.scss'

export default
@inject('FormState', 'EditableGuidelinesStore') @observer
class extends React.Component {
  render () {
    return <div className={styles.container}>
      <FetchedDataLoader data={temporaryLoginPageMessage} fetched />
      <Button
        className={styles.button}
        variant='contained'
        href='/login'
        color='primary'
        children='Login'
      />
    </div>
  }
}
