import { observable, action, computed } from 'mobx'
import { getEditableFields, submitEditableFields } from 'transport/editable-fields'
import { pick, omit } from 'lodash'

class EditableFieldsStore {
  @observable fetched = false
  @observable submitting = false
  @observable traveler = {
    firstName: {},
    lastName: {},
    netid: {},
    email: {},
    department: {},
    title: {},
    yearOfTerminalDegree: {}
  }

  @observable contact = {
    email: {}
  }

  @observable travelDetails = {
    startDate: {},
    endDate: {},
    eventTitle: {},
    destination: {},
    participationLevel: {},
    primaryMethodOfTravel: {}
  }

  @observable travelCosts = {
    primaryTransport: {},
    secondaryTransport: {},
    mileage: {},
    registration: {},
    mealsAndLodging: {}
  }

  @action async fetchAll () {
    if (!this.fetched) {
      await getEditableFields()
        .then(res => res.forEach(each => { this[each.stepName][each.fieldSlug] = each }))
      this.fetched = true
    }
  }

  genericMappable = (stepName) => {
    return Object.keys(this[stepName]).map(each => omit(this[stepName][each], 'id'))
  }

  @action async submit () {
    this.submitting = true
    const allKeys = Object.keys(omit(this, ['fetched', 'submitting']))
    const pickCriteria = ['id', 'label', 'sideBarInstruction']
    const requestBody = []
    allKeys.forEach(eachKey =>
      Object.keys(this[eachKey]).forEach(eachObject =>
        requestBody.push(pick(this[eachKey][eachObject], pickCriteria))
      )
    )
    await submitEditableFields(requestBody)
    this.submitting = false
  }

  @action onLabelChange (event, stepName, fieldSlug) {
    this[stepName][fieldSlug].label = event.target.value
  }

  @action onSideBarInstructionChange (newContent, stepName, fieldSlug) {
    this[stepName][fieldSlug].sideBarInstruction = newContent
  }

  @computed get mappableContactFields () {
    return this.genericMappable('contact')
  }

  @computed get mappableTravelerFields () {
    return this.genericMappable('traveler')
  }

  @computed get mappableTravelDetailsFields () {
    return this.genericMappable('travelDetails')
  }

  @computed get mappableTravelCostsFields () {
    return this.genericMappable('travelCosts')
  }
}

const singleton = new EditableFieldsStore()
export default singleton
