import React from 'react'
import { inject, observer } from 'mobx-react'
import TableCell from '@material-ui/core/TableCell'
import Table from '@material-ui/core/Table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableBody from '@material-ui/core/TableBody'
import TablePagination from '@material-ui/core/TablePagination'
import LinearProgress from '@material-ui/core/LinearProgress'
import TableSortLabel from '@material-ui/core/TableSortLabel'
import Icon from '@material-ui/core/Icon'
import IconButton from '@material-ui/core/IconButton'

import { Link } from 'react-router-dom'
import { get } from 'lodash'
import { format, parseISO } from 'date-fns'

import styles from './styles.scss'

/*
Description:
 - TripTable is component used to create the tables of Trip records as seen in Travel Requests and Reimbursement Reminders pages
How to use:
 - TripTable takes in 'displayedTrips' and 'allTrips' as props.
 - 'displayedTrips' are an array of Trip records that are displayed on the current page in the table
 - 'allTrips' are an array of the all array of the Trip records that can be displayed
*/

export default
@inject('TripStore') @observer
class TripTable extends React.Component {
    columns = [
      {
        label: 'ID',
        property: 'id',
        value: trip => trip.id,
        sortable: true
      },
      {
        label: 'Fiscal Year',
        property: 'FundingPeriod.fiscalYear',
        value: trip => get(trip, 'FundingPeriod.fiscalYear', ''),
        sortable: true
      },
      {
        label: 'Status',
        property: 'status',
        value: trip => trip.status,
        sortable: true
      },
      {
        label: 'Traveler',
        property: 'firstName',
        value: trip => `${trip.firstName} ${trip.lastName}`,
        sortable: true
      },
      {
        label: 'Submission',
        property: 'createdAt',
        value: trip => format(parseISO(trip.createdAt), 'MMM do, yyyy h:mma'),
        sortable: true
      },
      {
        label: 'Last Date Reminded',
        value: trip => trip.lastDateReminded ? format(parseISO(trip.lastDateReminded), 'MMM do, yyyy h:mma') : 'N/A'
      },
      {
        label: 'Actions',
        value: trip =>
          <IconButton
            size='small'
            component={React.forwardRef((props, ref) =>
              <Link to={`/admin/trips/${trip.id}`} ref={ref} {...props} />)}>
            <Icon>edit</Icon>
          </IconButton>
      }
    ]
    render () {
      const { TripStore, displayedTrips, allTrips } = this.props
      return <React.Fragment>
        <Table>
          <Head
            sortDirection={TripStore.sortDirection}
            sortProperty={TripStore.sortProperty}
            columns={this.columns}
            onSort={(column) => TripStore.handleSort(column)}
          />
          <Body
            loading={TripStore.fetching}
            trips={displayedTrips}
            columns={this.columns}
          />
        </Table>
        <TripPagination allTrips={allTrips} />
      </React.Fragment>
    }
}

const Head = observer(({ sortDirection, sortProperty, columns, onSort }) =>
  <TableHead>
    <TableRow>
      {columns.map(column =>
        <TableCell key={column.label}>
          {column.sortable
            ? (
              <TableSortLabel
                direction={sortDirection}
                active={sortProperty === column.property}
                onClick={() => onSort(column)}
                children={column.label}
              />
            )
            : column.label}
        </TableCell>
      )}
    </TableRow>
  </TableHead>
)

const Body = observer(({ loading, trips, columns }) =>
  <TableBody>
    { loading &&
      <TableRow className={styles.progressRow}>
        <TableCell colSpan={columns.length}>
          <LinearProgress />
        </TableCell>
      </TableRow> }
    {trips.map(trip =>
      <TableRow
        key={trip.id}
        hover>
        {columns.map(column =>
          <TableCell
            key={column.label}
            className={styles.tableCell}>
            {column.value(trip)}
          </TableCell>
        )}
      </TableRow>)}
  </TableBody>
)

@inject('TripStore') @observer
class TripPagination extends React.Component {
  componentDidMount () {
    this.props.TripStore.resetPageNumber()
  }
  render () {
    const { TripStore, allTrips } = this.props
    return <TablePagination
      component='div'
      count={allTrips.length}
      page={TripStore.page}
      rowsPerPage={TripStore.rowsPerPage}
      rowsPerPageOptions={[10, 25, 50]}
      onChangePage={(_, page) => { TripStore.page = page }}
      onChangeRowsPerPage={ev => { TripStore.rowsPerPage = ev.target.value }}
    />
  }
}
