import React from 'react'
import { inject, observer } from 'mobx-react'

import Icon from '@material-ui/core/Icon'
import FetchedDataLoader from 'components/FetchedDataLoader'

import styles from './styles.scss'

export default
@inject('FormState', 'EditableGuidelinesStore') @observer
class extends React.Component {
  render () {
    const { EditableGuidelinesStore: { fetched, finished } } = this.props
    return <div className={styles.container}>
      <h1 className={styles.heading}>Request Submitted</h1>
      <FetchedDataLoader data={finished} fetched={fetched} />
      <Icon className={styles.done} color='primary'>done</Icon>
    </div>
  }
}
