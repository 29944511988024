import React from 'react'
import { inject, observer } from 'mobx-react'

import SmartInputGenerator from 'containers/SmartInputGenerator'
import BackNextButtons from 'containers/BackNextButtons'

import styles from './styles.scss'

export default
@inject('FormState', 'ValidationState', 'EditableFieldsStore') @observer
class extends React.Component {
  render () {
    return <div className={styles.container}>
      <SmartInputGenerator data={this.props.EditableFieldsStore.mappableTravelCostsFields} />
      <BackNextButtons />
    </div>
  }
}
