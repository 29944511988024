import React from 'react'

import { inject, observer } from 'mobx-react'
import FieldsInput from './FieldsInput'
import GeneralEditablesForm from 'containers/GeneralEditablesForm'

const FieldInputMapper = (props) =>
  props.fetched ? props.fields.map(each => <FieldsInput key={each.field} {...each} />) : <p>Fetching</p>

export default
@inject('EditableFieldsStore') @observer
class FieldsEdit extends React.Component {
  componentDidMount () {
    this.props.EditableFieldsStore.fetchAll()
  }
  render () {
    const { EditableFieldsStore:
                { mappableContactFields,
                  mappableTravelerFields,
                  mappableTravelDetailsFields,
                  mappableTravelCostsFields,
                  fetched }
    } = this.props

    return <GeneralEditablesForm storeName={'Editable Fields'}>
      <FieldInputMapper fields={mappableContactFields} fetched={fetched} />
      <FieldInputMapper fields={mappableTravelDetailsFields} fetched={fetched} />
      <FieldInputMapper fields={mappableTravelerFields} fetched={fetched} />
      <FieldInputMapper fields={mappableTravelCostsFields} fetched={fetched} />
    </GeneralEditablesForm>
  }
}
