import React from 'react'

import draftToHtml from 'draftjs-to-html'
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'

export default class ContentEditor extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      editorState: props.content ? this.createEditorStateFromProps(props.content) : EditorState.createEmpty()
    }
  }

  createEditorStateFromProps = (content) => {
    const blocksFromHtml = convertFromHTML(content)
    const initialContentState = ContentState.createFromBlockArray(
      blocksFromHtml.contentBlocks,
      blocksFromHtml.entityMap
    )
    return EditorState.createWithContent(initialContentState)
  }

  componentDidUpdate () {
    if (!this.state.editorState.getCurrentContent().hasText()) {
      this.setState({
        editorState: this.createEditorStateFromProps(this.props.content)
      })
    }
  }

    onEditorStateChange = (editorState) => {
      this.setState({
        editorState
      })
    }

    onContentStateChange = () => {
      const newContent = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent()))
      this.props.onContentStateChange(newContent)
    }

    render () {
      return <Editor
        editorState={this.state.editorState}
        onEditorStateChange={this.onEditorStateChange}
        onContentStateChange={this.onContentStateChange}
      />
    }
}
