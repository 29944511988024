import { get, put } from 'stores/TransportState'

export const getEditableFields = () =>
  get('/api/editable-fields')
    .then(x => x.json())

export const submitEditableFields = (body) =>
  put(`/api/editable-fields`, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
