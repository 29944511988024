import React from 'react'
import { inject, observer } from 'mobx-react'

import GeneralEditablesForm from 'containers/GeneralEditablesForm'
import MailingOptionsInput from './mailingOptionsInput'

export default
@inject('EditableMailingOptionsStore') @observer
class MailingOptionsEdit extends React.Component {
  componentDidMount () {
    this.props.EditableMailingOptionsStore.fetchAll()
  }
  render () {
    const { EditableMailingOptionsStore: { allKeys } } = this.props
    return <GeneralEditablesForm storeName={'Editable Mailing Options'}>
      {allKeys && allKeys.map(key =>
        <MailingOptionsInput key={key} mailingOptionKey={key} />
      )}
    </GeneralEditablesForm>
  }
}
