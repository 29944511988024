import lang from 'lang/en_US'
import racpiIdToDepartmentMap from 'data/racpiIdToDepartmentMap.json'

export const filterableIdToLabel = {
  'status': 'Status',
  'FundingPeriod.fiscalYear': 'Fiscal Year'
}
export const formStepShortNames = Object.keys(lang.formSteps)

export const selectOptions = {
  'travelDetails.participationLevel': [
    'Active Participation',
    'Attendance Only'
  ],
  'travelDetails.primaryMethodOfTravel': [
    'Airfare',
    'Bus',
    'Personal Car',
    'Train'
  ],
  'traveler.title': [
    'Academic/Research Asst/Assoc',
    'Adjunct Lecturer',
    'Assistant Professor',
    'Assistant Professor In Residence',
    'Associate Professor',
    'Associate Professor In Residence',
    'Asst/Assoc/Full Clinical Professor',
    'Asst/Assoc/Full Ext. Professor',
    'Asst/Assoc/Full Research Professor',
    'Cooperative Ext. Educator In Residence',
    'Cooperative Extension Educator',
    'Emeritus',
    'Instructor (Asst., In-Residence, Clinical)',
    'Law Professor',
    'Lecturer',
    'Professor',
    'Professor In Residence'
  ],
  'traveler.department': [
    ...(
      // Convert to a set to deduplicate departments
      new Set(
        Object.keys(racpiIdToDepartmentMap)
          .map(key => racpiIdToDepartmentMap[key])
          .sort())
        .values()
    )
  ]
}

export const temporaryLoginPageMessage = ` <p>Welcome to the Faculty Travel Funding Request Form! Faculty travel funding is available for members of the AAUP bargaining unit at Storrs, Law School, and regional campuses only. UConn Health faculty are not eligible. Please see eligibility section of the <a target="_blank" rel="noopener noreferrer" href="https://ovpr.uconn.edu/services/research-development/faculty-travel">guidelines</a>.</p>
<p>Receiving an AAUP/OVPR Travel Award <strong>does not infer that such travel has been approved by the University</strong>, only that the awardee has travel funding available to reimburse expenses from approved travel. Before traveling or making travel arrangements:</p>                                                                                                                  
<ul>                                                                                                                                                                                                                                                                                                                                                                                              
 <li>All Faculty must obtain approval from their department or unit head, per the AAUP contract.</li>                                                                                                                                                                                                                                                                                            
 <li>Faculty traveling internationally must obtain approval from Global Affairs.</li>                                                                                                                                                                                                                                                                                                            
 <li>Faculty traveling to embargoed regions (currently Cuba, Iran, Syria, North Korea, and Crimea) must seek approval from OVPR Export Control.</li>                                                                                                                                                                                                                                             
</ul>                                                                                                                                                                                                                                                                                                                                                                                             
<p>Login with your NetID to begin.</p>`
