import React from 'react'

import BackNextButtons from 'containers/BackNextButtons'
import SmartInputGenerator from 'containers/SmartInputGenerator'
import AgreementCriteriaConfirmation from 'containers/AgreementCriteriaConfirmation'
import FetchedDataLoader from 'components/FetchedDataLoader'

import { inject, observer } from 'mobx-react'

export default
@inject('EditableGuidelinesStore', 'EditableFieldsStore') @observer
class extends React.Component {
  componentDidMount () {
    this.props.EditableFieldsStore.fetchAll()
  }
  render () {
    const { EditableGuidelinesStore: { welcomeText, fetched }, EditableFieldsStore: { mappableContactFields } } = this.props
    return <div>
      <FetchedDataLoader data={welcomeText} fetched={fetched} />
      <SmartInputGenerator data={mappableContactFields} />
      <AgreementCriteriaConfirmation />
      <BackNextButtons />
    </div>
  }
}
