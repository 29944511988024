import React from 'react'
import { inject, observer } from 'mobx-react'

import { Accordion, AccordionChild } from 'components/Accordion'
import BackNextButtons from 'containers/BackNextButtons'
import RemainingFunds from 'containers/RemainingFunds'

import styles from './styles.scss'
import SmartInputGenerator from '../SmartInputGenerator'

export default
@inject('FormState', 'ValidationState', 'EditableFieldsStore') @observer
class extends React.Component {
  render () {
    const { FormState, ValidationState, EditableFieldsStore: { mappableTravelerFields } } = this.props
    const travelerIdentities = mappableTravelerFields && mappableTravelerFields.slice(0, 3)
    const travelerExtraInfo = mappableTravelerFields && mappableTravelerFields.slice(3)
    const validNetID = !ValidationState.traveler.errors.netid && FormState.traveler.netid.length === 8

    return <div className={styles.container}>
      <div className={styles.identityFields}>
        <SmartInputGenerator data={travelerIdentities} />
      </div>
      <Accordion className={styles.accordion}>
        { validNetID ? <AccordionChild><RemainingFunds /></AccordionChild> : null }
      </Accordion>
      <SmartInputGenerator data={travelerExtraInfo} />
      <BackNextButtons />
    </div>
  }
}
