import { get, put } from 'stores/TransportState'

export const getGuidelines = () =>
  get(`/api/editable-guidelines`)
    .then(x => x.json())

export const submitGuidelines = body =>
  put(`/api/editable-guidelines`, {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
