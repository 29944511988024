import React from 'react'
import { inject, observer } from 'mobx-react'
import currencyFormatter from 'currency-formatter'

import FetchedDataLoader from 'components/FetchedDataLoader'
import styles from './styles.scss'

export default
@inject('FormState', 'EditableGuidelinesStore') @observer
class extends React.Component {
  render () {
    const { FormState, EditableGuidelinesStore: { remainingFunds, fetched } } = this.props

    if (isNaN(FormState.fairShareLeft)) {
      return null
    }

    const fairShareLeft = currencyFormatter.format(FormState.fairShareLeft, { code: 'USD' })

    return <div className={styles.container}>
      <p>Available funds for <strong>{FormState.traveler.netid}</strong>: {fairShareLeft}</p>
      <FetchedDataLoader data={remainingFunds} fetched={fetched} />
    </div>
  }
}
