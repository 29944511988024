import { get, put } from 'stores/TransportState'

export const getMailingOptions = () =>
  get('/api/editable-mailing-options')
    .then(x => x.json())

export const submitMailingOptions = body =>
  put('/api/editable-mailing-options', {
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  })
