import React from 'react'

import { inject, observer } from 'mobx-react'
import ReadOnlyTextField from 'components/ReadOnlyTextField'
import ContentEditor from 'components/ContentEditor'
import EditablesPaper from 'components/EditablesPaper'

export default
@inject('EditableGuidelinesStore') @observer
class GuidelinesInput extends React.Component {
  render () {
    const { EditableGuidelinesStore, guidelineKey } = this.props
    const content = EditableGuidelinesStore[guidelineKey]
    return <EditablesPaper>
      <ReadOnlyTextField label={'Guideline Key'} value={guidelineKey} />
      <ContentEditor
        content={content}
        onContentStateChange={(newContent) => EditableGuidelinesStore.onChange(newContent, guidelineKey)}
      />
    </EditablesPaper>
  }
}
